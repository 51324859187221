.g-link {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: rr(12);
  width: max-content;

  @include media-query-small {
    gap: rr(14);
  }

  // .g-link__text

  &__text {
    font-weight: bold;
    font-size: rr(16);
    color: var(--black__primary--hex);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .g-link__icon-wrap

  &__icon-wrap {
    display: grid;
    justify-content: center;
    align-items: center;
    width: rr(45);
    height: rr(45);
    border-radius: 50%;
    border: 1px solid var(--primary__color--hex);

    @include media-query-small {
      width: rr(64);
      height: rr(64);
    }
  }

  // .g-link__icon

  &__icon {
    width: rr(6);
    fill: var(--primary__color--hex);

    @include media-query-small {
      width: rr(8);
    }
  }
}
