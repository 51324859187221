.p-pulldown {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid var(--gray__primary--hex);
  border-radius: var(--global-radius);

  // .p-pulldown__select
  &__select {
    width: 100%;
    padding: rr(7) rr(40) rr(7) rr(12);
    font-size: 16px;
    color: var(--black__primary--hex);

    @include media-query-small {
      padding: rr(7) rr(48) rr(7) rr(20);
      font-size: rr(18);
    }

    &:focus {
      outline: none;
    }
  }

  // .p-pulldown__list
  &__list {
    text-align: center;
  }

  &__icon {
    position: absolute;
    right: rr(16);
    max-width: rr(8);
    pointer-events: none;
    fill: var(--primary__color--hex);
    transform: rotate(90deg);
  }
}
