.g-ad-overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: index($z-index, ad);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--black__primary--rgb), 0.3);
  // .g-ad-overlay__wrap
  &__wrap {
    > * {
      max-width: 100%;
    }
    @include media-query-small {
      padding: rr(8) rr(40);
    }
  }
  // .g-ad-overlay__button--close
  &__button--close {
    display: none;
    @include media-query-small {
      display: block;
      position: absolute;
      left: rr(8);
      top: rr(8);
    }
  }
  // .g-ad-overlay__icon
  &__icon {
    fill: var(--white__primary--hex);
  }
}
