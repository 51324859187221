.l-footer {
  background-color: var(--black__primary--hex);
  display: grid;
  gap: rr(24);
  padding: rr(32) var(--global-inner-large);
  @include media-query-small {
    display: flex;
    align-items: center;
    padding: rr(28) rr(64);
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  > .g-sns {
    justify-content: center;
    @include media-query-small {
      flex: 1;
      justify-content: right;
    }
  }


  // .l-footer__list
  &__list {
    display: grid;
    justify-items: center;
    gap: rr(16);
    @include media-query-small {
      grid-auto-flow: column;
      gap: rr(24);
    }
  }

  // .l-footer__item
  &__item {
    font-size: rr(12);
    @include media-query-small {
      font-size: rr(14);
    }
  }

  // .l-footer__link
  &__link {
    display: block;
    color: var(--white__primary--hex);
  }

  // .l-footer__logo
  &__logo {
    width: rr(164);
    margin: 0 auto;
    @include media-query-small {
      margin-left: auto;
      margin-right: 0;
      width: rr(240);
    }
  }

  // .l-footer__image
  &__image {
    width: 100%;
  }
}
