.p-monthly {
  padding: rr(16) var(--global-inner-small) rr(24);
  background-color: var(--white__primary--hex);

  @include media-query-small {
    padding: 0 var(--global-inner-small);
    background-color: transparent;
  }

  @at-root .l-main > &:first-child {
    padding-top: var(--page-top-section-padding);
  }

  // .p-blog-monthly__inner

  &__inner {
    display: grid;
    gap: rr(16);

    @include media-query-small {
      position: relative;
      z-index: 1;
      gap: rr(20);
      padding: rr(32) var(--global-inner-padding) rr(48);
      overflow: hidden;
      background-color: var(--white__primary--hex);
      border-radius: var(--global-radius);
    }
  }

  // .p-blog-monthly__head

  &__head {
    @include flex-gap(rr(8));

    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    @include media-query-small {
      @include flex-gap(rr(12));
    }

    .p-blog-monthly-sort {
      margin-left: auto;
    }
  }

  // .p-blog-monthly__title

  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .p-blog-monthly__item

  &__item {
    display: grid;
    align-content: start;
    border-bottom: 1px solid var(--gray__primary--hex);

    &:where(:first-child) {
      border-top: 1px solid var(--gray__primary--hex);
    }
  }

  // .p-blog-monthly__link

  &__link {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
    padding: rr(16) rr(14) rr(16) rr(12);
    color: var(--black__primary--hex);
  }

  // .p-blog-monthly__date

  &__date {
    font-size: rr(16);

    @include media-query-small {
      font-size: rr(18);
    }
  }

  // .p-blog-monthly__number

  &__number {
    margin-left: auto;
    font-size: rr(13);
    color: var(--gray__secondary--hex);

    @include media-query-small {
      margin-left: rr(16);
      font-size: rr(15);
    }
  }

  // .p-blog-monthly__icon

  &__icon {
    display: none;
    width: rr(14);
    height: rr(12);
    margin-left: auto;
    fill: var(--primary__color--hex);

    @include media-query-small {
      display: block;
      width: rr(14);
      height: rr(12);
    }
  }
}
