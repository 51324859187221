$g-accordion__clipping--small: rr(8);
$g-accordion__clipping--large: rr(10);
.g-accordion {
  display: grid;
  align-content: start;
  gap: rr(28);
  @include media-query-small {
    gap: rr(64);
  }
  // .g-accordion__button
  &__button {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: rr(16);
    font-weight: 600;
  }
  // .g-accordion__text
  &__text {
    margin-left: rr(-10);
    font-size: rr(16);
    font-weight: 600;
    color: var(--black__primary--hex);
    @include media-query-small {
      font-size: rr(22);
    }
    @at-root .p-business & {
      color: var(--white__primary--hex);
    }
  }
  // .g-accordion__icon-wrap
  &__icon-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: rr(45);
    height: rr(45);
    @include media-query-extra-small {
      width: rr(52);
      height: rr(52);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid var(--primary__color--hex);
      filter: contrast(0.75);
      border-radius: 50%;
      // -1pxは上と左でborderが見切れるため
      clip-path: polygon(-1px -1px, 100% -1px, 100% $g-accordion__clipping--small, 50% $g-accordion__clipping--small, 50% calc(100% - #{$g-accordion__clipping--small}), 100% calc(100% - #{$g-accordion__clipping--small}), 100% 100%, 0 100%);
      @include media-query-extra-small {
        clip-path: polygon(-1px -1px, 100% -1px, 100% $g-accordion__clipping--large, 50% $g-accordion__clipping--large, 50% calc(100% - #{$g-accordion__clipping--large}), 100% calc(100% - #{$g-accordion__clipping--large}), 100% 100%, 0 100%);
      }
      @at-root .p-business & {
        border: 1px solid var(--white__primary--hex);
      }
    }
  }
  // .g-accordion__icon
  &__icon {
    transform: rotate(90deg);
    width: rr(6);
    fill: var(--primary__color--hex);
    filter: contrast(0.75);
    @include media-query-small {
      width: rr(8);
    }
    @at-root .p-business & {
      fill: var(--white__primary--hex);
    }
  }
  p {
    overflow: hidden;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    @at-root .g-accordion--close p {
      display: -webkit-box;
    }
  }
}
