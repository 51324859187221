.p-profile {
  @include media-query-small {
    padding: 0 var(--global-inner-small);
    margin-top: 0;
  }

  @at-root .l-main > &:first-child {
    @include media-query-small {
      padding-top: var(--page-top-section-padding);
    }
  }

  // .p-profile__inner

  &__inner {
    display: grid;
    gap: rr(16);
    padding: rr(20) var(--global-inner-small);
    background-color: var(--white__primary--hex);

    @include media-query-small {
      padding: rr(32) var(--global-inner-padding) rr(64);
      border-radius: rr(12);
    }

    @at-root .l-main > .p-profile:first-child & {
      padding-top: var(--page-top-section-padding);

      @include media-query-small {
        padding: rr(32) rr(64);
      }
    }
  }

  // .p-profile__body

  &__body {
    display: grid;
    gap: rr(16);

    @include media-query-small {
      display: flex;
      gap: 0;

      @include flex-gap(rr(16), 0);

      width: 100%;
    }
  }

  // .p-profile__thumbnail

  &__thumbnail {
    max-width: 100%;

    @include media-query-small {
      max-width: rr(360);
      margin-right: rr(32);
    }
  }

  // .p-profile__image

  &__image {
    position: relative;
    z-index: 1;
    max-width: 100%;
    overflow: hidden;
    border-radius: var(--global-radius);
  }

  // .p-profile__wrap

  &__wrap {
    display: grid;
    flex: 1;
    gap: rr(24);

    @include media-query-small {
      height: fit-content;
    }
  }

  // .p-profile__name-wrap

  &__name-wrap {
    display: flex;
    flex-direction: column;

    @include flex-gap(rr(16), 0);
  }

  // .p-profile__name

  &__name {
    font-size: rr(18);
    font-weight: 600;
    line-height: 1.6;

    @at-root .p-profile__name-wrap > &:only-child {
      margin-bottom: rr(-12);
    }

    @include media-query-small {
      height: fit-content;
      font-size: rr(20);
    }
  }

  // .p-profile__text

  &__text {
    font-size: rr(16);
    line-height: 1.75;

    @at-root .p-profile__name-wrap + & {
      padding-top: rr(16);
      border-top: 1px solid var(--gray__primary--hex);
    }
  }
}
