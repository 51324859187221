%g-pagination__label-set {
  max-width: 100%;
  font-size: rr(14);
  font-weight: bold;

  @include media-query-small {
    font-size: rr(18);
  }
}

.g-pagination {
  display: grid;
  margin: rr(16) 0 0;

  @include media-query-small {
    justify-content: center;
    padding: 0 var(--global-inner-large);
    margin: rr(24) 0 0;
  }

  // .g-pagination__inner

  &__inner {
    display: flex;
    height: rr(49);
    overflow: hidden;
    border-radius: var(--global-radius);

    @include media-query-small {
      min-width: rr(528);
      height: rr(60);
    }

    > * {
      flex: 1;
      background-color: var(--primary__color--hex);

      @include media-query-small {
        min-width: rr(102);
      }
    }
  }

  // .g-pagination__center

  &__center {
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    border: 1px solid var(--white__primary--hex);
    border-top: none;
    border-bottom: none;

    @include media-query-small {
      min-width: max-content;
    }
  }

  // .g-pagination__disabled

  &__disabled {
    pointer-events: none;
    opacity: 0.5;

    > * {
      opacity: 0.35;
    }
  }

  // .g-pagination__select-wrap

  &__select-wrap {
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    width: rr(140);
    height: 100%;
    padding: 0 rr(12 + 10 + 12); // space + icon-width + space

    @include media-query-small {
      width: rr(230);
      padding: 0 rr(24 + 12 + 24); // space + icon-width + space
    }
  }

  // .g-pagination__select

  &__select {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;

    option {
      color: var(--black__primary--hex);
    }
  }

  // .g-pagination__icon

  &__icon {
    // .g-pagination__icon--arrow

    &--arrow {
      @include inversion_main_color;

      position: absolute;
      top: 0;
      right: rr(12);
      bottom: 0;
      width: rr(10);
      height: rr(10);
      margin: auto;
      cursor: pointer;
      transform: rotate(90deg);

      @include media-query-small {
        right: rr(24);
        width: rr(12);
        height: rr(12);
      }
    }

    // .g-pagination__icon--apps

    &--apps {
      width: rr(20);
      height: rr(20);

      @include inversion_main_color;

      fill: var(--primary__color--hex);

      @include media-query-small {
        width: rr(24);
        height: rr(24);
      }
    }
  }

  // .g-pagination__link

  &__link {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--primary__color--hex);
  }

  // .g-pagination__label

  &__label {
    @include inversion_main_color;
    @extend %g-pagination__label-set;

    color: var(--primary__color--hex);

    @at-root .g-pagination__select-wrap & {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
