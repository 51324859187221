.p-drawer-terms {
  display: grid;
  gap: rr(24);
  font-size: rr(16);

  // .p-drawer-terms__item .p-drawer-terms

  &__item & {
    padding-left: rr(8);
  }

  // .p-drawer-terms__item

  &__item {
    display: grid;
    gap: rr(24);
  }

  // .p-drawer-terms__link

  &__link {
    display: grid;
    gap: rr(12);
    align-items: center;
  }

  // .p-drawer-terms__image

  &__image {
    max-width: 100%;
  }

  // .p-drawer-terms__title-wrap

  &__title-wrap {
    display: grid;
    gap: rr(4);
    align-items: center;
  }

  // .p-drawer-terms__title

  &__title {
    // .p-drawer-terms__title--large

    &--large {
      font-size: rr(16);
      font-weight: bold;
      color: var(--white__primary--hex);
    }

    // .p-drawer-terms__title--small

    &--small {
      font-size: rr(14);
      font-weight: bold;
      color: #b5b5b5;
    }
  }
}
