.c-text {
  // .c-text--validation
  &--validation {
    font-size: rr(14);
    line-height: 1.6;
    color: var(--red__primary--hex);

    @include media-query-small {
      font-size: rr(16);
    }
  }
}
