.p-contact {
  background-color: var(--white__primary--hex);

  @include media-query-small {
    padding: 0 var(--global-inner-small);
    background-color: transparent;
  }

  @at-root .l-main > &:first-child {
    @include media-query-small {
      padding-top: var(--page-top-section-padding);
    }
  }

  // .p-contact__inner
  &__inner {
    position: relative;
    z-index: 2;
    display: grid;
    gap: rr(24);
    padding: rr(32) var(--global-inner-large);

    @at-root .l-main > *:first-child & {
      padding-top: var(--page-top-section-padding);

      @include media-query-small {
        padding: rr(48) rr(64);
      }
    }

    @include media-query-small {
      grid-template-columns: repeat(auto-fit, minmax(#{rr(320)}, 1fr));
      gap: rr(24) rr(64);
      align-items: center;
      padding: rr(48) var(--global-inner-padding);
      overflow: hidden;
      background-color: var(--white__primary--hex);
      border-radius: 12px;
    }
  }

  // .p-contact__wrap
  &__wrap {
    display: grid;
    gap: rr(12);
  }

  // .p-contact__title
  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .p-contact__text
  &__text {
    font-size: rr(15);
    font-weight: 600;
    line-height: 1.75;

    @include media-query-small {
      font-size: rr(18);
    }
  }

  // .p-contact__button
  &__button {
    display: grid;
    padding: rr(14) rr(24);
    background-color: var(--primary__color--hex);
    border-radius: var(--global-radius);

    @include media-query-small {
      justify-self: center;
      width: 100%;
      padding: rr(16) rr(24);
    }
  }

  &__lead {
    @include inversion_main_color;

    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-items: center;
    font-size: rr(15);
    font-weight: bold;
    color: var(--primary__color--hex);
    gap: rr(12);

    @include media-query-small {
      font-size: rr(20);
    }
  }

  // .p-contact__icon
  &__icon {
    fill: var(--primary__color--hex);

    @include media-query-small {
      width: rr(8);
    }
  }

  // .p-contact__background
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--primary__color--rgb), 0.15);
  }
}
