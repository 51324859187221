.js-animation-passing {
  overflow: hidden;
  position: relative;
  display: inline-block;
  // .js-animation-passing__el
  &__el {
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    background-color: var(--primary__color--hex);
  }
}
