.p-instagram {
  @include media-query-small {
    padding: 0 var(--global-inner-small);
  }

  @at-root .l-main > &:first-child {
    @include media-query-small {
      padding-top: var(--page-top-section-padding);
    }
  }

  // .p-instagram__inner

  &__inner {
    margin-top: rr(16);
    background-color: var(--white__primary--hex);

    @include media-query-small {
      display: flex;
      align-items: center;
      margin-top: 0;
      border-radius: rr(12);
      overflow: hidden;
    }
  }

  // .p-instagram__head

  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: rr(8) var(--global-inner-small) rr(16);

    @include flex-gap(rr(16), 0);

    @include media-query-small {
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto 0;
    }

    @at-root .l-main > .p-instagram:first-child & {
      padding-top: var(--page-top-section-padding);

      @include media-query-small {
        padding: rr(32) rr(64);
      }
    }
  }

  // .p-instagram__body

  &__body {
    @include media-query-small {
      width: 50%;
    }
  }

  // .p-instagram__title-wrap

  &__title-wrap {
    display: grid;
    gap: rr(4);

    @include media-query-small {
      align-items: center;
      justify-items: center;
      margin-top: 0;
    }
  }

  // .p-instagram__title

  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(36);
    }
  }

  // .p-instagram__text

  &__text {
    font-size: rr(15);

    @include media-query-small {
      font-size: rr(18);
    }
  }

  // .p-instagram__button

  &__button {
    display: grid;
    width: fit-content;
    min-width: rr(120);
    padding: rr(6) rr(12);
    margin-left: auto;
    background: linear-gradient(45deg, rgba(254, 212, 117, 1) 0%, rgba(229, 61, 93, 1) 50%, rgba(194, 49, 134, 1) 70%, rgba(156, 56, 187, 1) 100%);
    border-radius: var(--global-radius);
    opacity: 0.7;

    @include media-query-small {
      justify-self: center;
      min-width: rr(172);
      padding: rr(10) rr(32);
      margin-top: rr(32);
      margin-left: 0;
    }
  }

  // .p-instagram__list

  &__list {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
  }

  // .p-instagram__item

  &__item {
    display: grid;
  }

  // .p-instagram__link

  &__link {
    display: block;
  }

  // .p-instagram__thumbnail

  &__thumbnail {
    @include aspect-ratio(1, 1);

    z-index: 1;
    overflow: hidden;
    background-color: var(--gray__primary--hex);
  }

  // .p-instagram__image

  &__image {
    will-change: transform;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;

    @at-root .p-instagram__link:hover & {
      @include media-query-small {
        @include hover-scale-active;
      }
    }

    &:not(.js-instagram__image--show) {
      display: none;
    }
  }

  // .p-instagram__lead

  &__lead {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: rr(12);
    align-items: center;
    justify-items: center;
    font-size: rr(15);
    font-weight: bold;
    color: var(--white__primary--hex);

    @include media-query-small {
      font-size: rr(18);
    }
  }

  // .p-instagram__icon

  &__icon {
    fill: var(--white__primary--hex);

    @include media-query-small {
      width: rr(8);
    }
  }
}
