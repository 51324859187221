.p-main-visual {
  position: relative;
  display: grid;
  background-color: var(--white__primary--hex);
  min-height: rr(160);
  gap: rr(8);

  @include media-query-small {
    min-height: rr(200);
    font-size: rr(28);
    background-color: transparent;
  }

  @at-root .l-main > &:first-child:not(:root) {
    padding-top: 0;
  }

  // .p-main-visual__inner

  &__inner {
    position: relative;
    z-index: 2;
    display: grid;
    grid-area: 1/1;
    gap: rr(8);
    align-self: end;
    padding: rr(24) var(--global-inner-small) rr(20);

    @include media-query-small {
      padding: rr(48) var(--global-inner-small);
    }

    @at-root .l-main > .p-main-visual:first-child & {
      padding-top: var(--page-top-section-padding);
    }
  }

  &__title {
    width: fit-content;
    margin: 0 auto;
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(28);
    }
  }

  &__text {
    width: fit-content;
    margin: 0 auto;
    font-size: rr(14);
    line-height: 1.65;

    @include media-query-small {
      font-size: rr(16);
    }
  }

  &__thumbnail {
    position: relative;
    z-index: 2;
    grid-area: 2/1;
    width: 100%;
    padding: 0 var(--global-inner-small) rr(20);
    
    @include media-query-small {
      padding: 0 var(--global-inner-small);
    }

    @at-root .p-main-visual__inner + & {
      margin-top: rr(-16);
      @include media-query-small {
        margin-top: rr(-40);
      }
    }

    
    // MVが最初のコンテンツだった場合
    @at-root .l-main > .p-main-visual &:first-child {
      padding-top:var(--page-top-section-padding);
    }

    // MVが最初のコンテンツでアイキャッチのみの場合
    @at-root .l-main > .p-main-visual:first-child &:first-child {
      margin-top: rr(-24);
      @include media-query-small {
        margin-top: rr(-8);
      }
    }


    // MVが最初のコンテンツ以外で、文字がない場合
    @at-root .l-main > .p-main-visual:not(:first-child) > &:first-child {
      padding-top: rr(24);
      @include media-query-small {
        padding-top: rr(48);
      }
    }
  }

  // .p-main-visual__image

  &__image {
    display: block;
    max-width: 100%;
    max-height: rr(1000);
    margin: 0 auto;
    border-radius: var(--global-radius);
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    grid-area: 1/1;
    width: 100%;
    height: 100%;
    background-color: var(--white__primary--hex);

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(var(--primary__color--rgb), 0.15);
    }

    @at-root .p-main-visual__thumbnail + & {
      height: 75%;
    }

    @at-root .p-main-visual &:first-child {
      padding-top: calc(var(--header-height, 5rem) + 2rem);
    }
  }
}
