[data-animation="scale-in"] {
  opacity: 0;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  &.js-animation-scale-in {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity .6s,-webkit-transform .8s cubic-bezier(.08,.92,.35,1);
    transition: opacity .6s,-webkit-transform .8s cubic-bezier(.08,.92,.35,1);
    transition: transform .8s cubic-bezier(.08,.92,.35,1),opacity .6s;
    transition: transform .8s cubic-bezier(.08,.92,.35,1),opacity .6s,-webkit-transform .8s cubic-bezier(.08,.92,.35,1);
  }
}
