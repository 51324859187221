.p-post-body {
  padding: rr(16) var(--global-inner-small) rr(32);
  background-color: var(--white__primary--hex);

  @include media-query-small {
    padding: 0 var(--global-inner-padding) rr(64);
  }

  @at-root .p-post__inner > &:first-child {
    padding-top:calc(var(--header-height, 3.5rem) + 2rem);
    @include media-query-small {
      padding-top:rr(48);
    }
  }
}
