:root {
  --global-inner-small: 5%;
  --global-inner-large: 5%;
  --global-margin: #{rr(16)};
  --global-radius: 6px;
  --line-height-m: 1.9;
  --page-top-section-padding: calc(var(--header-height, #{rr(76)}) + #{rr(16)});
  --editor-margin:0!important;
  @include media-query-small {
    --global-inner-small: clamp(10%, 100vw, calc((100% - 960px) / 2));
    --global-inner-large: clamp(10%, 100vw, calc((100% - 1536px) / 2));
    --global-inner-padding:#{rr(64)};
    --global-margin: #{rr(24)};
    --line-height-m: 2.2;
    --page-top-section-padding: calc(var(--header-height, #{rr(80)}) + #{rr(0)});
  }
}


