// .g-ogp-card
.g-ogp-card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: rr(16);
  overflow: hidden;
  background-color: transparent;
  border: 1px solid var(--gray__primary--hex);
  border-radius: var(--global-radius);
  &:not(root) {
    margin-top: 0;
  }

  @include media-query-small {
    flex-direction: row;
    align-items: center;
    padding: rr(24);
  }

  &:hover .g-ogp-card__image {
    @include media-query-small {
      @include hover-scale-active;
    }
  }

  // .g-ogp-card__wrap
  &__wrap {
    position: relative;
    flex: 1;
    margin-right: rr(12);

    @include media-query-small {
      padding: 0;
      margin-right: rr(24);
    }
  }

  // .g-ogp-card__title
  &__title {
    margin-top: 0;
    margin-bottom: rr(6);
    font-size: rr(14);
    font-weight: bold;
    line-height: 1.6;

    @include clamp(2);

    @include media-query-small {
      font-size: rr(18);
    }
  }

  // .g-ogp-card__text
  &__text {
    font-size: rr(12);
    line-height: 1.6;

    @include clamp(2);

    @include media-query-small {
      font-size: rr(14);
      line-height: 1.8;
    }
  }

  // .g-ogp-card__thumbnail
  &__thumbnail {
    @include aspect-ratio(4, 3);

    --global-radius: #{rr(6)};

    width: rr(116);
    border: none;

    @include media-query-small {
      @include aspect-ratio(16, 9);

      --global-radius: #{rr(6)};

      width: rr(232);
    }
  }

  // .g-ogp-card__image
  &__image {
    @include media-query-small {
      @include hover-scale-still;
    }
  }
}
