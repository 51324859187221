:root {
  --black__primary--hex: #111;
  --black__primary--rgb: 17, 17, 17;

  --white__primary--hex: #fff;
  --white__primary--rgb: 255, 2555, 255;

  --gray__primary--hex: #dbdbdb;
  --gray__primary--rgb: 219, 219, 219;
  --gray__secondary--hex: #7a7a7a;

  --red__primary--hex: #e01e5a;
}
