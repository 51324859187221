$intersection-black: '[data-intersection-header="black"]';
$intersection-white: '[data-intersection-header="white"]';
%intersection-set {
  transition: color 0.25s ease-in-out;

  @at-root #{$intersection-black} & {
    color: var(--black__primary--hex);
  }

  @at-root #{$intersection-white} & {
    color: var(--white__primary--hex);
  }
}

.l-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: index($z-index, header);
  display: flex;
  width: 100%;
  padding: rr(24) 5%;
  opacity: 0;

  @include media-query-small {
    padding: rr(42) rr(60);
  }

  // .l-header__logo-wrap

  &__logo-wrap {
    position: relative;
    display: block;
    width: max-content;
    height: max-content;
  }

  // .l-header__logo

  &__logo {
    display: block;
    object-fit: contain;
    object-position: left top;

    &.js-length--vertical {
      max-width: rr(70);
      max-height: rr(140);

      @include media-query-small {
        max-width: rr(90);
        max-height: rr(180);
      }
    }

    &.js-length--horizontal {
      max-width: rr(200);
      max-height: rr(80);

      @include media-query-small {
        max-width: rr(400);
        max-height: rr(100);
      }
    }

    &.js-length--square {
      max-width: rr(80);
      height: auto;

      @include media-query-small {
        max-width: rr(140);
        height: auto;
      }
    }

    // svgは幅の取得ができないため
    &[src$="svg"] {
      width: 100vw;
    }
  }

  // .l-header__menu

  &__menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: rr(12);
    justify-items: start;
    width: 100%;
    padding: rr(32) 5% rr(24);
    background-color: transparent;

    @include media-query-small {
      gap: rr(24);
      padding: rr(32) rr(60) rr(42);
    }

    &.js-header__menu--sticky {
      position: fixed;
      z-index: 1;
      align-items: center;
      padding: rr(16) rr(20);
      background-color: rgba(var(--black__primary--rgb), 0.6);

      @include media-query-small {
        padding: rr(16) rr(60);
      }
    }
  }

  // .l-header__name

  &__name {
    align-self: center;
    font-size: rr(12);
    font-weight: 600;
    line-height: normal;
    color: var(--white__primary--hex);

    @include media-query-small {
      font-size: rr(18);
    }

    @at-root .l-header__logo-wrap + .l-header__menu:not(.js-header__menu--sticky) & {
      display: none;
    }

    @at-root .l-header__menu:not(.js-header__menu--sticky) & {
      @extend %intersection-set;

      color: var(--black__primary--hex);
    }
  }

  // .l-header__button

  &__button {
    // .l-header__button--open

    &--open {
      display: grid;
      grid-column: 2;
      grid-auto-flow: column;
      gap: rr(8);
      align-items: center;
      align-self: start;
      justify-content: center;
      justify-self: end;
      color: var(--white__primary--hex);

      @include media-query-small {
        gap: rr(12);
      }

      @at-root .l-header__menu:not(.js-header__menu--sticky) & {
        @extend %intersection-set;

        color: var(--black__primary--hex);
      }
    }
  }

  // .l-header__icon

  &__icon {
    fill: currentcolor;
  }

  // .l-header__label

  &__label {
    display: none;

    @include media-query-small {
      display: block;
      font-size: rr(24);
      font-weight: 600;
      letter-spacing: 0.05em;
      white-space: nowrap;
    }
  }
}

// .js-header__intersection

.js-header__intersection {
  position: absolute;
  top: 25vh; // l-header__menuがfixedになる位置
}
