.g-form {
  padding: rr(16) var(--global-inner-small) rr(32);
  background-color: var(--white__primary--hex);

  @include media-query-small {
    padding: rr(24) var(--global-inner-small);
    background-color: transparent;
  }

  @at-root .l-main > &:first-child {
    padding-top: var(--page-top-section-padding);
  }

  // .g-form__inner
  &__inner {
    display: grid;
    gap: rr(16);

    @include media-query-small {
      gap: rr(16);
      padding: rr(42) var(--global-inner-padding);
      background-color: var(--white__primary--hex);
      border-radius: rr(12);
    }
  }

  // . g-form__title
  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .g-form__text
  &__text {
    margin-bottom: rr(8);
    line-height: 1.75;
    text-align: left;

    @include media-query-small {
      font-size: rr(16);
    }
  }

  // .g-form__required {
  &__required {
    margin-bottom: rr(16);

    @include media-query-small {
      font-size: rr(16);
    }
  }

  // .g-form__wrap
  &__wrap {
    display: grid;
    gap: rr(16);
    padding-bottom: rr(16);

    @include media-query-small {
      border-top: 1px solid #e0e0e0;
    }

    &:last-child {
      @include media-query-small {
        border-bottom: 1px solid #e0e0e0;
      }
    }

    &::before {
      display: block;
      width: 100vw;
      margin: 0 calc(50% - 50vw);
      content: "";
      border-bottom: 1px solid #e0e0e0;

      @include media-query-small {
        content: none;
      }
    }

    @include media-query-small {
      display: table;
      width: 100%;
      padding: rr(24) 0;
      table-layout: fixed;
    }
  }

  // .g-form__label
  &__label {
    font-weight: 600;
    text-align: left;

    @include media-query-small {
      display: table-cell;
      width: 30%;
      padding-right: rr(60);
      font-size: rr(18);
      vertical-align: middle;
    }

    &:only-child {
      width: 100%;
      padding-right: 0;
    }
  }

  // .g-form__data
  &__data {
    display: grid;
    gap: rr(8);
    font-size: rr(16);

    @include media-query-small {
      display: table-cell;
      vertical-align: middle;
    }
  }

  // .g-form__validation {
  &__validation {
    margin-top: rr(4);

    @include media-query-small {
      margin-top: rr(12);
    }
  }

  // .g-form__foot
  &__foot {
    margin-top: rr(12);
  }

  // .g-form__button
  &__button {
    display: grid;
    gap: rr(16);
    width: 100%;
    margin-top: rr(16);

    @include media-query-small {
      grid-auto-flow: column;
      gap: rr(32);
      justify-content: center;
      margin-top: rr(40);
    }

    @at-root .c-input--checkbox input ~ & {
      pointer-events: none;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
    }

    @at-root .c-input--checkbox input:checked ~ & {
      pointer-events: inherit;
      opacity: 1;
    }
  }

  // .g-form__agreement
  &__agreement {
    margin-bottom: rr(16);
    text-align: center;
  }

  // .g-form__check
  &__check {
    display: grid;
    justify-items: center;
    font-size: rr(14);
    text-align: center;

    @include media-query-small {
      font-size: rr(20);
    }
  }
}
