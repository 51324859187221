.p-default-head {
  position: relative;
  display: grid;
  background-color: var(--white__primary--hex);

  // p-default-head__inner
  &__inner {
    display: grid;
    gap: rr(16);
    padding: rr(24) var(--global-inner-small) rr(16);
    background-color: var(--white__primary--hex);

    @include media-query-small {
      gap: 0;
      padding: 0;
      background-color: transparent;
    }

    @at-root .l-main > .p-default:first-child & {
      padding-top: var(--page-top-section-padding);
      background-color: rgba(var(--primary__color--rgb), 0.1);
      padding-bottom: rr(16);

      @include media-query-small {
        gap: 0;
        padding: 0;
        background-color: var(--white__primary--hex);
      }
    }
  }

  // .p-default-head__wrap
  &__wrap {
    display: grid;
    gap: rr(4);

    @include media-query-small {
      gap: rr(16);
      padding: rr(48) var(--global-inner-padding) rr(24);
    }

    @at-root .p-default-head__eyecatch + & {
      @include media-query-small {
        padding-top: rr(32);
      }
    }
  }

  // .p-default-head__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);
    
    position: relative;
    z-index: 1;
    border-radius: rr(6);

    @at-root .l-main > .p-default:first-child & {
      margin-top: rr(-16);
      @include media-query-small {
        margin-top: 0;
      }
    }
   
    @include media-query-small {
      border-radius: 0;
    }
  }

  // .p-default-head__title

  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(28);
    }
  }

  // .p-default-head__categories

  &__categories {
    @include flex-gap(rr(4), rr(12));

    display: flex;
    flex-wrap: wrap;

    @include media-query-small {
      @include flex-gap(rr(4), rr(12));
    }
  }

  // .p-default-head__category

  &__category {
    display: grid;
    align-items: center;
    font-size: rr(13);
    font-weight: bold;
    color: var(--primary__color--hex);
    text-decoration: underline;

    @include media-query-small {
      font-size: rr(15);
    }

    &:visited {
      color: var(--primary__color--hex);
    }
  }

  // .p-default-head__times

  &__times {
    display: grid;
    gap: rr(2);
  }

  // .p-default-head__time

  &__time {
    display: grid;
    font-size: rr(13);
    font-weight: bold;
    color: #818181;

    @include media-query-small {
      font-size: rr(15);
    }
  }
}
