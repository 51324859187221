.p-drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: index($z-index, drawer);
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:not(.js-drawer--show) {
    display: none;
  }

  // .p-drawer__menu

  &__menu {
    position: relative;
    display: grid;
    gap: rr(24);
    align-content: start;
    width: 100%;
    height: 100%;
    padding: rr(24) rr(32) rr(48);
    margin-left: auto;
    overflow-y: scroll;
    background-color: rgba(var(--black__primary--rgb), 0.75);

    @include media-query-small {
      gap: rr(38);
      width: 56vw;
      max-width: 560px;
      padding: rr(100) rr(68) rr(48);
      padding-top: rr(32);
    }
  }

  // .p-drawer__button-wrap

  &__button-wrap {
    @include flex-gap(20px);

    display: flex;
    flex-flow: column wrap;

    @include media-query-small {
      flex-flow: row-reverse wrap;
    }
  }

  // .p-drawer__button

  &__button {
    // .p-drawer__button--close

    &--close {
      display: grid;
      grid-auto-flow: column;
      gap: rr(20);
      align-items: center;
      align-self: end;
      justify-self: end;
      min-height: 32px;
      color: var(--white__primary--hex);

      @include media-query-small {
        top: rr(32);
        right: rr(62);
      }
    }
  }

  // .p-drawer__sns-wrap

  &__sns-wrap {
    margin-right: auto;
  }

  // .p-drawer__icon

  &__icon {
    fill: currentcolor;
  }

  // .p-drawer__label

  &__label {
    display: none;
    font-size: rr(18);
    font-weight: bold;
    color: currentcolor;
    white-space: nowrap;

    @include media-query-small {
      display: block;
    }
  }

  // .p-drawer__list

  &__list {
    display: grid;
    align-content: start;
  }

  // .p-drawer__item

  &__item {
    display: grid;
    gap: rr(24);
    padding: rr(24) 0;

    &[data-emphasis-menu="true"] {
      @include full-screen-width-background(rgba(var(--black__primary--rgb), 0.5));
    }

    // 同じDOMが隣接しているときだけpadding-topを削除する。
    &[data-emphasis-menu="true"] + &[data-emphasis-menu="true"],
    &[data-emphasis-menu="false"] + &[data-emphasis-menu="false"] {
      padding-top: 0;
    }
  }

  // .p-drawer__nests

  &__nests {
    display: grid;
    gap: rr(24);
    padding-left: rr(8);
  }

  // .p-drawer__link

  &__link {
    display: grid;
    grid-template-columns: 1fr;
    gap: rr(12);
  }

  // .p-drawer__title-wrap

  &__title-wrap {
    position: relative;
    display: grid;
    gap: rr(4);
    align-items: center;
    min-height: rr(52);
    padding-right: rr(16 + 7); // margin + iconWidth

    @include media-query-small {
      min-height: rr(58);
    }
  }

  // .p-drawer__title

  &__title {
    // .p-drawer__title--large

    &--large {
      font-size: rr(16);
      font-weight: bold;
      color: var(--white__primary--hex);

      @include media-query-small {
        font-size: rr(18);
      }
    }

    // .p-drawer__title--small

    &--small {
      font-size: rr(14);
      font-weight: bold;
      color: #b5b5b5;

      @include media-query-small {
        font-size: rr(16);
      }
    }
  }

  // .p-drawer__arrow

  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    width: rr(7);
    height: rr(12);
    fill: var(--white__primary--hex);
    transform: translateY(-50%);
  }

  // .p-drawer__image

  &__image {
    max-width: 100%;
  }

  // .p-drawer__overlay

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--black__primary--rgb), 0.5);
  }
}
