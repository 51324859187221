@page {
  margin: 0;
}

@media print {
  html {
    font-size: 10px;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .l-header__body.js-header__body--fixed {
    position: relative;
    background-color: transparent;
  }

  .l-main > section {
    page-break-after: always;
  }
}
