.p-default-body {
  padding: rr(16) var(--global-inner-small) rr(32);
  background-color: var(--white__primary--hex);

  @include media-query-small {
    padding: rr(48) var(--global-inner-padding) rr(64);
  }

  @at-root .p-default-head + & {
    @include media-query-small {
      padding-top: 0;
    }
  }

  // p-default-headがない場合
  @at-root .l-main > .p-default .p-default__inner > &:first-child {
    padding-top: rr(24);

    @include media-query-small {
      padding-top: rr(48);
    }
  }

  // p-defaultが最初の要素でアイキャッチとタイトルがない場合
  @at-root .l-main > .p-default:first-child .p-default__inner > &:first-child {
    padding-top: var(--page-top-section-padding);

    @include media-query-small {
      padding-top: rr(48);
    }
  }

  //p-defaultが2番目以降でアイキャッチがある場合
  @at-root .l-main > .p-default:not(:first-child) .p-default-head + & {
    padding-top: 0;
  }
}
