.p-youtube {

  @include media-query-small {
    margin-top: 0;
    padding: 0 var(--global-inner-small);
  }
  @at-root .l-main > &:first-child {
    padding-top: var(--page-top-section-padding);
  }
  
  @at-root .l-main > *:not([class]):first-child & {
    padding-top: var(--page-top-section-padding);
  }
  
  // .p-youtube__inner
  &__inner {
    padding: 0 var(--global-inner-small);
    margin: 0 auto;

    @include media-query-small {
      padding: 0;
      // width: rr(640); 大きさ指定するか検討
    }
  }

  // .p-youtube__thumbnail

  &__thumbnail {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    border-radius: 6px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
