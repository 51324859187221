.p-post-head {
  position: relative;
  display: grid;
  background-color: var(--white__primary--hex);

  // .p-post-head__inner
  &__inner {
    display: grid;
    gap: rr(16);
    padding: rr(24) var(--global-inner-small) rr(20);
    background-color: var(--white__primary--hex);

    @include media-query-small {
      gap: 0;
      padding: 0;
    }

    @at-root .l-main > .p-post:first-child & {
      padding-top: var(--page-top-section-padding);
      padding-bottom: rr(20);
      background-color: rgba(var(--primary__color--rgb), 0.1);

      @include media-query-small {
        gap: 0;
        padding: 0;
        background-color: var(--white__primary--hex);
      }
    }
  }

  // .p-post-head__wrap
  &__wrap {
    display: grid;
    gap: rr(4);

    @include media-query-small {
      gap: rr(16);
      padding: rr(48) var(--global-inner-padding);
    }

    .p-post-head__eyecatch + & {
      @include media-query-small {
        padding-top: rr(24);
      }
    }
  }

  // .p-post-head__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);

    position: relative;
    z-index: 1;
    margin-top: rr(-16);
    border-radius: rr(6);

    @include media-query-small {
      margin-top: 0;
      border-radius: 0;
    }
  }

  // .p-post-head__title

  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(28);
    }
  }

  // .p-post-head__categories

  &__categories {
    display: flex;
    flex-wrap: wrap;
    transform: translateY(4px);

    @include flex-gap(rr(4), rr(12));

    @include media-query-small {
      @include flex-gap(rr(4), rr(12));
    }
  }

  // .p-post-head__category

  &__category {
    display: grid;
    align-items: center;
    font-size: rr(13);
    font-weight: bold;
    color: var(--primary__color--hex-darken);
    text-decoration: underline;

    @include media-query-small {
      font-size: rr(15);
    }

    &:visited {
      color: var(--primary__color--hex-darken);
    }

    &:hover {
      text-decoration: none;
    }
  }

  // .p-post-head__times

  &__times {
    display: grid;
    gap: rr(2);
  }

  // .p-post-head__time

  &__time {
    display: grid;
    font-size: rr(13);
    font-weight: bold;
    color: var(--gray__secondary--hex);

    @include media-query-small {
      font-size: rr(15);
    }
  }
}
