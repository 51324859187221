[data-budoux] {
  position: relative;

  > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
