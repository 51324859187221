.p-drawer-sns {
  @include flex-gap(20px);

  display: flex;
  flex-flow: row wrap;

  // .p-drawer-sns__link

  &__link {
    display: block;
  }

  // .p-drawer-sns__icon

  &__icon {
    width: 32px;
    height: 32px;
  }
}
