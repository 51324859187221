.l-main {
  position: relative;
  z-index: index($z-index, main);
  display: grid;
  gap: rr(16);
  width: 100%;
  max-width: 100%;
  background-color:rgba(var(--gray__primary--rgb), 0.65);

  @include media-query-small {
    gap: rr(36);
    padding-bottom: rr(64);
    background-color:rgba(var(--gray__primary--rgb), 0.35);
  }

  .p-main-visual:first-child + .p-news {
    margin-top: rr(-16);

    @include media-query-small {
      margin-top: 0;
    }
  }
}

