.p-archive-sort {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: rr(16);

  // .p-archive-sort__list

  &__list {
    display: grid;
    border-top: 1px solid var(--gray__primary--hex);

    @include media-query-small {
      border-top: none;
      gap: rr(40) rr(28);
      grid-template-columns: repeat(auto-fill, minmax(#{rr(320)}, 1fr));
    }
  }
}
