.p-post-sns {
  margin-top: rr(32);

  @include media-query-small {
    margin-top: rr(64);
  }

  // .p-post-sns__inner

  &__inner {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 16px;
    align-items: center;
    padding: rr(16) rr(32);
    background-color: #f7f7f7;

    @include media-query-small {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      padding: rr(20) rr(32);
      margin: 0 auto;
    }
  }

  // .p-post-sns__text

  &__text {
    font-size: rr(14);
    font-weight: bold;
    line-height: 1.6;

    @include media-query-small {
      font-size: rr(16);
      text-align: right;
    }
  }

  // .p-post-sns__list

  &__list {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 16px;
  }

  // .p-post-sns__item

  &__item {
    width: 32px;
  }

  // .p-post-sns__link

  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }

  // .p-post-sns__image

  &__image {
    width: 32px;
  }
}
