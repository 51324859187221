.g-sns {
  display: grid;
  grid-template-columns: repeat(auto-fit, 32px);
  align-items: center;
  gap: rr(21);
  // .g-sns__link
  &__link {
    display: block;
  }
  // .g-sns__icon
  &__icon {
    display: block;
    width: 32px;
    height: 32px;
  }
}
