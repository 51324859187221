.p-news {
  padding: rr(16) var(--global-inner-small);
  background-color: var(--white__primary--hex);

  @include media-query-small {
    padding: 0 var(--global-inner-small) rr(24);
    background-color: transparent;
  }

  @at-root .l-main > &:first-child {
    padding-top: var(--page-top-section-padding);
  }

  // .p-news__inner

  &__inner {
    display: grid;
    gap: rr(12);

    @include media-query-small {
      gap: rr(16);
    }
  }

  // .p-news__head

  &__head {
    font-size: rr(18);
    font-weight: bold;

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .p-news__body
  &__body {
    display: grid;
    gap: rr(16);

    @include media-query-small {
      gap: rr(48);
    }
  }

  // .p-news__list

  &__list {
    @include media-query-small {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: rr(20);
      overflow: hidden;
    }
  }

  // .p-news__item

  &__item {
    display: grid;
    align-content: start;

    @include media-query-small {
      display: flex;
      padding: 5.5%;
      background-color: var(--white__primary--hex);
      border-radius: 6px;
    }

    &::after {
      display: block;
      width: 100vw;
      margin: 0 calc(50% - 50vw);
      content: "";
      border-bottom: 1px solid var(--gray__primary--hex);

      @include media-query-small {
        content: none;
      }
    }
  }

  // .p-news__link

  &__link {
    position: relative;
    display: grid;
    gap: rr(12);
    padding: rr(20) 0;

    @at-root .p-news__item:first-child & {
      padding-top: 0;
    }

    @include media-query-small {
      display: flex;
      flex-direction: column;
      gap: 0;
      width: 100%;
      padding: 0;
    }
  }

  // .p-news__thumbnail

  &__thumbnail {
    @include aspect-ratio(16, 9);

    z-index: 1;
    border-radius: var(--global-radius);
  }

  // .p-news__image

  &__image {
    will-change: transform;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;

    @at-root .p-news__link:hover & {
      @include media-query-small {
        @include hover-scale-active;
      }
    }
  }

  // .p-news__title

  &__title {
    @include clamp(3);

    font-size: rr(16);
    font-weight: bold;
    line-height: 1.6;
    color: var(--black__primary--hex);

    @include media-query-small {
      @include clamp(5);

      margin-top: rr(4);
    }

    @at-root .p-news__thumbnail ~ & {
      @include media-query-small {
        @include clamp(3);

        padding-top: 0;
        border-top: none;
      }
    }
  }

  &__description {
    display: none;

    @include media-query-small {
      @include clamp(6);

      padding-top: rr(10);
      margin: rr(8) 0 rr(16);
      font-size: rr(13);
      line-height: 1.6;
      color: var(--gray__secondary--hex);
      border-top: 1px solid var(--gray__primary--hex);
    }

    @at-root .p-news__thumbnail ~ & {
      @include media-query-small {
        @include clamp(2);

        padding-top: 0;
        border-top: none;
      }
    }
  }

  // .p-news__categories

  &__categories {
    @include flex-gap(rr(8), rr(12));

    display: flex;
    flex-wrap: wrap;
    margin-top: rr(4);

    @include media-query-small {
      @include flex-gap(rr(4), rr(12));

      margin-top: auto;
    }
  }

  // .p-news__category

  &__category {
    font-size: rr(13);
    font-weight: bold;
    color: var(--primary__color--hex-darken);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  // .p-news__date

  &__date {
    align-self: flex-start;
    font-size: rr(14);
    color: var(--gray__secondary--hex);

    @include media-query-small {
      margin-top: rr(8);
      font-size: rr(13);
      font-weight: bold;
    }
  }

  // .p-news__button

  &__button {
    display: grid;
    padding: rr(14) rr(24);
    background-color: var(--primary__color--hex);
    border-radius: var(--global-radius);

    @include media-query-small {
      justify-self: center;
      width: rr(440);
      padding: rr(16) rr(24);
    }
  }

  // .p-news__lead

  &__lead {
    @include inversion_main_color;

    display: grid;
    grid-template-columns: 1fr max-content;
    gap: rr(12);
    align-items: center;
    justify-items: center;
    font-size: rr(15);
    font-weight: bold;
    color: var(--primary__color--hex);

    @include media-query-small {
      font-size: rr(20);
    }
  }

  // .p-news__icon

  &__icon {
    fill: var(--primary__color--hex);

    @include media-query-small {
      width: rr(8);
    }
  }
}
