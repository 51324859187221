// 値から単位を抜く関数 16px - px ;
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

// 可変フォントサイズ関数
@function rpx($standard_px, $max_px) {
  // 指定箇所
  $standard_device_width: 375; // 基準にする端末横幅 iphone6
  // 変換(普段触る必要なし)
  $standard_px: strip-unit($standard_px);
  $max_px: strip-unit($max_px);
  $standard_device_width_conversion: $standard_device_width / 100;
  $breakpoint-large: strip-unit($breakpoint-large);
  $breakpoint-large_conversion: $breakpoint-large / 100; // ブレイクポイント変数を入れる
  $viewport-small_width: 1vw - #{$standard_device_width_conversion + px};
  $responsive_width: $breakpoint-large_conversion - $standard_device_width_conversion;
  $calc_minmax: $max_px - $standard_px;
  $calc_minmax-breakpoint: $calc_minmax / $responsive_width;
  @return calc(#{$standard_px + px} + ((#{$viewport-small_width}) * #{$calc_minmax-breakpoint}));

  // 指定方法 font-size: rpx(iphone6時のサイズ, ブレイクポイント時のサイズ);
}

// pxからvwに変換
@function convert-small($num, $width: $viewport-small) {
  @return (1vw * $num / $width * 100);
}

@function convert-medium($num, $width: $viewport-medium) {
  @return (1vw * $num / $width * 100);
}

@function convert-large($num, $width: $viewport-large) {
  @return (1vw * $num / $width * 100);
}

// rr()をremに計算後、単位をremに変換
// Responsive Rem = rr
@function rr($number) {
  @return #{$number / 16 + rem};
}

// 割合 親要素のコンテンツ幅を元に％を割り出す式
// Percent = p 自分のコンテンツ幅 / 親のコンテンツ幅(paddingを除く内側)
@function p($thisWidth, $parentWidth) {
  @return ($thisWidth / $parentWidth) * 100%;
}
