.p-post {
  @include media-query-small {
    padding: rr(32) var(--global-inner-small);
  }

  @at-root .l-main > &:first-child {
    @include media-query-small {
      padding-top: var(--page-top-section-padding);
    }
  }

  // .p-post__inner
  &__inner {
    display: grid;

    @include media-query-small {
      position: relative;
      z-index: 1;
      overflow: hidden;
      background-color: var(--white__primary--hex);
      border-radius: rr(12);
    }
  }
}
